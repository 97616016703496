import './aboutUs.css';

const areas = [
    {id: "first", title: "Quem Somos?", description: "A Twinkle é uma startup portuguesa composta por uma equipa de pessoas apaixonadas e dedicadas a repensar a experiência da restauração. Somos impulsionados pelo desejo de inovar num sector que tem permanecido largamente inalterado em Portugal, e estamos comprometidos em torná-lo mais eficiente e conveniente para todos."},
    {id: "second", title: "Missão?", description: "Lutamos por um mundo em que a evolução acontece alinhada com os interesses da sociedade, queremos ser a força de intervenção necessária para promover essa mudança. Caminharemos lado a lado com esta missão para criar produtos inovadores que sirvam os interesses das pessoas."},
    {id: "third", title: "A Nossa Equipa", description: "Nós somos uma equipa de jovens empreendedores que pensa fora da caixa, animada com o desafio de inovar na indústria da restauração. A nossa paixão pelo que fazemos alimenta a nossa determinação e motiva-nos a transformar a maneira como experimentamos a gastronomia."},
];

const AboutUs = () => {
  return (
    <div id='aboutUs'>
        { areas.map((area, idx) => (
            <div className={`aboutLine ${area.id}`} key={idx}>
                <h2 className='aboutTitle'>{area.title}</h2>
                <p className='aboutDescription'>{area.description}</p>
            </div>
        ))}
    </div>
  )
}

export default AboutUs