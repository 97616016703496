import './appContent.css';
import React from 'react';

import { useState } from 'react';

import appleLogo from '../../utils/logos/apple.svg';
import googlePlay from '../../utils/logos/googlePlay.svg';
import bulletPoint from '../../utils/logos/bulletPoint.svg';
import mainImage from '../../utils/logos/mainImage.png';

const features = [
    {name: "Reserva", description: "Uma vez encontrado o teu restaurante ideal, fazer a reserva é tão simples quanto um toque. Escolhe a data, a hora e o número de pessoas para a reserva, quando chegares ao restaurante basta fazer check-in."},
    {name: "Pedido", description: "Esquece os menus tradicionais. Com a Twinkle, podes ver todos os pratos e bebidas disponíveis, ler as descrições e até ver fotos de cada opção. Quando estiveres pronto, submete o teu pedido diretamente através da app."},
    {name: "Pagamento", description: "Pronto para pagar? Não há necessidade de esperar pelo empregado. Podes pagar a conta diretamente na app, inclusive separar a conta se estiveres a partilhar a refeição com amigos. E ainda, podes deixar a gratificação com apenas um toque."},
    {name: "Avaliação", description: "Avalia os pratos enquanto degustas! Terminada a refeição, é hora de avaliares a tua experiência geral. A tua opinião é fundamental para nós e para os outros clientes. Com as tuas avaliações, conseguimos personalizar ainda mais as tuas futuras recomendações. "}
];

const AppContent = () => {

    const [selectedFeature, setSelectedFeature] = useState("");

    return (
        <div id='appContent'>
            <h1 className='AppTitle'>Saboreie o futuro da restauração à sua maneira.</h1>
            <p className='mainDescription'>Descobre uma ampla variedade de restaurantes com base nos teus gostos e preferências. Usa os nossos filtros inteligentes para refinar a tua busca e descobrir novos lugares que vão ao encontro do que procuras.</p>

            <div className='featuresAndImage'>
                <div className='appFeatures'>
                    {features.map((feature, idx) => {
                        const isSelectedFeature = selectedFeature === feature.name;

                        return (
                            <React.Fragment key={idx}>
                                <div className='featureNameDiv'>
                                    { isSelectedFeature &&
                                        <img src={bulletPoint} alt="bullet point" />
                                    }
                                    <span onClick={!isSelectedFeature ? () => setSelectedFeature(feature.name) : () => setSelectedFeature("")} className={isSelectedFeature ? 'selectedFeature' : ''}>
                                        {feature.name}
                                    </span>
                                </div>
                                { isSelectedFeature &&
                                    <div className='featureExtension'>
                                        <p className='featureDescription'>{feature.description}</p>
                                    </div>
                                }
                            </React.Fragment>
                        );
                    })}

                    <div className='appButtons' style={selectedFeature === "" ? { marginTop: "15rem" } : { marginTop: "2.75rem" }}>
                        <button className='installAppButton' style={{ marginRight: "6.44rem" }}>
                            <img src={appleLogo} className="appLogo" alt="apple" />
                            <span className='appName'>App Store</span>
                        </button>

                        <button className='installAppButton'>
                            <img src={googlePlay} className="appLogo" alt="google play" />
                            <span className='appName'>Google Play</span>
                        </button>
                    </div>
                </div>

                <img className={`mainImage ${selectedFeature === "" ? "activeFeature" : ""}`} src={mainImage} alt="App Images" />
            </div>
        </div>
    )
}

export default AppContent