import './contact.css';

import { useState } from 'react';

const Contact = () => {

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log("email: ", email);
        console.log("assunto: ", subject);
        console.log("message: ", message);
        setEmail("");
        setSubject("");
        setMessage("");
      };

    return (
        <div id='contact'>
            <h2 className='contactTitle'>Contactos</h2>
            <form onSubmit={handleSubmit} className='contactForm'>
                <input value={email} onChange={(e) => setEmail(e.target.value)} className='email' placeholder='O teu endereço de e-mail' />
                <input value={subject} onChange={(e) => setSubject(e.target.value)} className='subject' placeholder='Assunto' />
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} className='text' placeholder='Envia-nos um e-mail' />
                <button>Enviar</button>
            </form>
        </div>
    )
}

export default Contact