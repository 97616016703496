import "./pageTabs.css";

const PageTabs = ({ tabs, activeSection }) => {
  
  return (
    <div className="pageTabs">
        { tabs.map((tab, idx) => (
            <a
              href={`#${tab.id}`}
              className={`tab ${tab.id === activeSection ? "currentTab": ""}`}
              style={idx === tabs.length -1 ? {marginRight: "0"} : {}}
              key={idx}
            >
              {tab.title}
            </a>
        ))}
    </div>
  )
}

export default PageTabs