import './footer.css';

import linkedin from '../../utils/logos/linkedin.svg';
import instagram from '../../utils/logos/instagram.svg';
import twitter from '../../utils/logos/twitter.svg';
import phone from '../../utils/logos/phone.svg';
import logo from "../../utils/logos/logo.png";

const Footer = () => {
  return (
    <div className='footer'>
        <div className='socials'>
            <div className='socialLogos'>
                <img src={linkedin} alt="linkedin" />
                <img src={instagram} alt="instagram" />
                <img src={twitter} alt="twitter" />
            </div>
            <div className='phone'>
                <img src={phone} alt="phone" />
                <span className='phoneNumber'>+351 999 090 090</span>
            </div>
        </div>

        <div className='image'>
            <img src={logo} alt="logo" />
            <span className='copyright'>Copyright © 2023 Twinkle. All Rights Reserved.</span>
        </div>

        <div className='legalInfo'>
            <span className='legal'>Legal</span>
            <span className='terms'>Termos de Serviço</span>
            <span className='privacy'>Aviso de Privacidade</span>
        </div>
    </div>
  )
}

export default Footer