import './App.css';
import { Header, AppContent, Footer, AboutUs, Contact } from './components';
import { useState, useEffect } from 'react';

const pageTabs = [
  {title: "App", id: "appContent"},
  {title: "Sobre nós", id: "aboutUs"},
  {title: "Contactos", id: "contact"}
];
const sections = ['appContent', 'aboutUs', 'contact'];

function App() {

  const [activeSection, setActiveSection] = useState(pageTabs[0].id);

  // discover for what section is beeing scrolled
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;

      const visibleSection = sections.find(sectionId => {
        const section = document.getElementById(sectionId);
        if (section) {
          const { top, bottom } = section.getBoundingClientRect();
          return top <= windowHeight / 2 && bottom >= windowHeight / 2;
        }
        return false;
      });

      if (visibleSection) {
        setActiveSection(visibleSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  return (
    <div className="App">
      <Header pageTabs={pageTabs} activeSection={activeSection} />
      <AppContent />
      <AboutUs />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
