import "./header.css";
import { PageTabs } from "../index";
import logo from "../../utils/logos/logo.png";

const Header = ({ pageTabs, activeSection }) => {
    // Function to handle the button click
    const handleButtonClick = () => {
        window.open('https://manager.twinkle.com.pt', '_blank');
    }

    return (
        <div className='header'>
            <img className="logo" src={logo} alt="logo" />
            <PageTabs tabs={pageTabs} activeSection={activeSection} />
            <button className="twinkleManagerButton" onClick={handleButtonClick}>Sou um restaurante</button>
        </div>
    )
}

export default Header;